html {
  background-color: #101010;
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: 100;
  color: white;
}

h1, h2 {
  margin: 0;
}

h2 {
  font-size: 1rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: lighter;
}

.mosaic {
  display: grid;
  margin: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.thumbnail-container {
  height: auto;
}

.thumbnail-title {
  position: relative;
  width: 100%;
  left: 0%;
  bottom: -80%;
  z-index: 4;
  height: 0;
  display: flex;
  justify-content: center;
  color: #cecece;
  background-color: black;
  pointer-events: none;
}
.thumbnail-title p {
  padding: 0.2vh 0.6vw;
  margin: auto;
  text-align: center;
  text-justify: center;
  z-index: 5;
  font-size: 1.5vw;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  width: 92%;
}

.thumbnail-image-container {
  border: #AAA solid 0.5px;
  min-width: 50px;
  text-align: bottom;
  text-justify: center;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  margin: 0.2rem;
  z-index: 1;
}
.thumbnail-image-container :hover {
  transition: all 3s ease-out;
  filter: grayscale(0%) blur(0px);
  transform: scale(1.1);
}

.mosaic-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-size: cover;
  filter: grayscale(100%) blur(0.5vw);
}

a {
  color: #FFF;
}

.post-body {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.post-body h1 {
  margin: 1rem;
}
.post-body p {
  font-size: 1.22rem;
  margin: 1rem 10%;
}
.post-body img {
  width: 90%;
}
.post-body code {
  margin: 1rem;
}